import React from 'react'
import omit from 'lodash/omit'
import { FormattedMessage } from 'react-intl'
import isEmail from 'validator/lib/isEmail'

import { getAttributesObject, getSMSUnsubscribeText } from '~/common/utils/helpers'
import get from 'lodash/get'
import isString from 'lodash/isString'
import { Link } from 'react-router-dom'
import { BULK_CONTACTS_COPY_IMPORT_CHUNK_MAX_SIZE } from '~/common/utils/constants'
import { count } from 'sms-length'

export const validateEmail = (email) => {
  const test =
    !email ||
    isEmail(String(email).toLowerCase(), {
      allow_utf8_local_part: false,
    })
  return !test ? <FormattedMessage id="validations.email" /> : undefined
}

export const validatePosibleEmail = (value) => {
  const test = (value || '').includes('@')
  return !test ? <FormattedMessage id="validations.text.email.nocolumn" /> : undefined
}

export const validateUrl = (url) => {
  // eslint-disable-next-line no-useless-escape
  const re = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,17}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)?/gi
  const test = !url || re.test(String(url).toLowerCase())
  return !test ? <FormattedMessage id="validations.url" /> : undefined
}

export const validateNumber = (value) =>
  value && isNaN(value) ? <FormattedMessage id="form.error.fieldOnlyNumber" /> : undefined

const maxLength = (max) => (value) =>
  value && value.length > max ? (
    <FormattedMessage id="validations.characters.max" values={{ max }} />
  ) : undefined

const minLength = (min, textId = 'validations.characters.min') => (value) =>
  value && value.length < min ? <FormattedMessage id={textId} values={{ min }} /> : undefined

export const minLength3SMSSender = minLength(3, 'validations.smsSenderId.minLength')
export const maxLength11 = maxLength(11)
export const maxLength255 = maxLength(255)
export const minLength3 = minLength(3)
export const minLength6 = minLength(6)

export const required = (value) =>
  value === undefined || value === null || (isString(value) && value.trim() === '') ? (
    <FormattedMessage id="validations.required" />
  ) : undefined
export const requiredFile = (value) => {
  if (get(value, 'error')) {
    return get(value, 'error')
  }
  if (!get(value, 'file') || !get(value, 'options') || !get(value, 'csv')) {
    return <FormattedMessage id="form.error.contacts.import.invalidFile" />
  }
  return undefined
}

export const requiredCustom = (value, label) =>
  value === undefined || value === null || value === '' ? (
    <FormattedMessage id="validations.required.custom" values={{ label }} />
  ) : undefined

export const requiredHtml = (value) =>
  value ? undefined : <FormattedMessage id="validations.html" />

export const selectUniqueValue = (value, form, props, name) => {
  const attributes = getAttributesObject(form.attributes)
  let obj = {
    email: form.email,
    first_name: form.first_name,
    last_name: form.last_name,
    ...attributes,
  }
  let newName = name.replace(/attributes.atr_/g, '')

  obj = omit(obj, [newName])
  const checkValue = Object.values(obj)
    .filter((i) => i && i)
    .some((i) => i === value)
  return checkValue ? <FormattedMessage id="validations.columns" /> : undefined
}

export const validateCopyPasteImport = (value) => {
  const rows = `${value}`.split(/\n/g).filter(Boolean)

  if (rows.length > BULK_CONTACTS_COPY_IMPORT_CHUNK_MAX_SIZE) {
    return (
      <FormattedMessage
        id="validations.import.copy-paste"
        values={{
          limit: BULK_CONTACTS_COPY_IMPORT_CHUNK_MAX_SIZE,
          link: (
            <Link to="/contacts/import/file/destination">
              <FormattedMessage id="validations.import.copy-paste.link" />
            </Link>
          ),
          count: rows.length,
        }}
      />
    )
  }
  return undefined
}

export const validateSMSSenderId = (value) => {
  const re = /^(?=.*[a-zA-Z])(?=.*[a-zA-Z0-9])([a-zA-Z0-9 ]{3,11})$/m
  const test = !value || re.test(String(value).trim())
  return !test ? <FormattedMessage id="validations.smsSenderId" /> : undefined
}

export const maxSmsPartsLength = (max) => (value = '') => {
  const unsubscribeMessage = getSMSUnsubscribeText()
  const message = count(value + unsubscribeMessage)
  return value && message.messages > max ? (
    <FormattedMessage id="validations.sms.max_parts" values={{ max }} />
  ) : undefined
}

export const maxSmsLength5 = maxSmsPartsLength(5)

import React from 'react'
import { Route } from 'react-router-dom'

import AsyncComponent from '~/components/AsyncComponent'

const IndexPage = AsyncComponent({
  component: () => import('./IndexPage'),
})

export const educationRoutes = (
  <>
    <Route exact index element={<IndexPage key="index" />} />
  </>
)
